import React, { useState, createContext } from "react";

const ThemeContext = createContext({
	location:{
		lat: 34.7242069,
		lng: -92.4780107
	},
	setLocation: () => {},
	getLocation: () => {}
});

const ThemeProvider = (props) => {
	const { children } = props;
	const [ location, setLocation ] = useState({
		lat: 34.7242069,
		lng: -92.4780107
	});

	async function ipLookUp(){
		try {
			const ipres = await fetch('https://api.ipify.org?format=json');
			const ipresdata = await ipres.json();
			const fetchUrl = "" + process.env.GATSBY_WP_BASE_URL + "/wp-json/perch_endpoint/v1/get_user_location/?ip=" + ipresdata.ip

			const res = await fetch(fetchUrl);
			const data = await res.json();
			const userLocationData = JSON.parse(data.body);

			if( userLocationData.lat && userLocationData.lon ){
				setLocation({
					lat: userLocationData.lat,
					lng: userLocationData.lon
				});
			} 
		} catch( error ){
			console.log(error);
		}
	}

	function getLocation() {
		if( ("geolocation" in navigator) && (location && (location.lat === 34.7242069)) ){
			// check if geolocation is supported/enabled on current browser
			if( navigator.geolocation ){
				try {
					navigator.geolocation.getCurrentPosition(
						function success(position) {
							if( position.coords.latitude && position.coords.longitude ){
								setLocation({
									lat: position.coords.latitude,
									lng: position.coords.longitude
								});
								//sortByDistance();
							}
						},
						function error(error_message) {
							ipLookUp();
						}  
					);
				} catch(error) {
					if( location && location.lat === 34.7242069){
						ipLookUp();
					}
				}
			} else {
				if( location && location.lat === 34.7242069){
					ipLookUp();
				}
			}
			
		} else {
			if( location && location.lat === 34.7242069){
				ipLookUp();
			}
		}
	}

	return (
		<ThemeContext.Provider value={{ location, setLocation: setLocation, getLocation: getLocation }}>
			{children}
		</ThemeContext.Provider>
	)
}

export default ThemeContext;

export { ThemeProvider };